<template>
  <div id="detail" v-if="dataInfo">
    <!-- 订单地址 -->
    <div v-if="address.length === 0" @click="onEdit" class="boxCenter address-noGet">
      <div class="address-noGet-wrap">
        <div class="address-noGet-left">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon_add"></use>
          </svg>
          <!-- 手动添加收货地址 -->
          <p class="address-noGet-text">{{language.l_190}}</p>
        </div>
        <van-icon name="arrow" color="#999"/>
      </div>
    </div>
    <div v-else @click="onEdit" class="address">
      <van-icon name="location-o" size="24" class="icon_padding" />
      <div span="19" class="address_details">
        <div class="details_layout">
          <span>{{address.receiver_name}}</span>
          <span>{{address.receiver_tel}}</span>
        </div>
        <p>{{allAddress}}</p>
      </div>
      <div span="2" class="right">
        <van-icon name="arrow" size="14" />
      </div>
    </div>
    <!-- 贸易方式 -->
    <div class="trade">
      <!-- 实际发货    自提 -->
      <van-cell @click="showPopup" :value="popup === '1' ? language.l_191 : language.l_40" is-link>
        <template #title>
          <!-- 发货方式 -->
          <span class="custom-title">{{language.l_192}}</span>
        </template>
      </van-cell>
      <van-popup v-model="show" position="bottom" :style="{height: '30%'}" round>
        <!-- 发货方式 -->
        <p class="model-header">{{language.l_192}}</p>
        <van-radio-group v-model="popup">
          <van-cell-group>
            <van-cell @click="sendGoods('1')" clickable class="pay_layout">
              <template #title>
                <!-- 实际发货 -->
                <span>{{language.l_191}}</span>
              </template>
              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>
            <van-cell @click="sendGoods('2')" clickable class="pay_layout">
              <template #title>
                <!-- 自提 -->
                <span>{{language.l_40}}</span>
              </template>
              <template #right-icon>
                <van-radio name="2" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </van-popup>
      <!-- 身份证号    请输入收货人身份证号 -->
      <van-field v-model="idCard" required :label="language.l_127" :placeholder="language.l_193"/>
      <div class="order_addres">
        <div v-for="(item,index) in products" :key="index">
          <van-card num="2" :title="item.goods_title" :thumb="item.goods_pic" class="order_center">
            <template #price>
              <span>${{item.vip_sale_price}}</span>
            </template>
            <template #desc >
              <span class="quantity">{{item.product_name}}</span>
            </template>
            <template #num>
              <p class="quan_tity">x{{item.number}}</p>
            </template>
          </van-card>
        </div>
        <!-- 订单备注    选填，添加订单备注 -->
        <van-field v-model="value" :label="language.l_194" :placeholder="language.l_195"/>
      </div>
    </div>
    <!-- 运费 -->
    <div class="freight">
      <!-- 运费 -->
      <van-cell :title="language.l_196" :value="`$ ${shipping_fee}`"/>
    </div>
    <!-- 支付方式 -->
    <div class="payment">
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell v-for="(item, index) in payment_list" :key="index" class="pay_layout" @click="radio = item.value" clickable>
            <template #title>
              <img :src="item.other" class="pay_img"/>
              <span>{{item.name}}</span>
            </template>
            <template #right-icon>
              <van-radio :name="item.value" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <!-- 应付金额 -->
    <div class="check">
      <div>
        <!-- 实付金额： -->
        <span>{{language.l_197}}</span>
        <span>{{expectPrice}}</span>
      </div>
      <!-- 提交订单 -->
      <p class="fixed-right" @click="onSumbit">{{language.l_198}}</p>
    </div>
  </div>
</template>

<script>
import {orderCheck, orderAdd} from '@api'

export default {
  data() {
    return {
      dataInfo: null,
      show: false,  //弹出层
      popup: '1',  //默认选中实际发货
      value: '',
      idCard: '',  //身份证号码
      radio: '2',
      products: null,
      shipping_fee: 0,  //运费
      payment_list: null,  //支付方式
      address: [],
      language: this.$store.getters.language,
      routerEnter: ''
    };
  },
  computed: {
    expectPrice() {  //实付金额
      const {vip_goods_amount} = this.dataInfo
      const {shipping_fee} = this
      let num = parseFloat(vip_goods_amount) + parseFloat(shipping_fee)
      return num.toFixed(2)
    },
    allAddress() {
      const {address} = this
      return `${address.countries} ${address.province} ${address.city} ${address.district} ${address.address}`
    }
  },
  created() {
    let params = this.$store.state.orderCheck;
    if(params) {
      this.getData(params)
    } else {
      this.$router.go(-1)
    }
  },
  methods: {
    getData(params) {
      orderCheck(params).then(res => {
        const {products, shipping_fee, payment_list, user} = res.data
        this.dataInfo = res.data
        this.products = products
        this.shipping_fee = shipping_fee.toFixed(2)
        this.payment_list = payment_list
        if(this.$store.state.selectCheckAdr === null || this.$store.state.selectCheckAdr === '') {
          this.address = user.address
          this.idCard = user.address.receiver_identity_card
        }else {
          this.address = this.$store.state.selectCheckAdr;
          this.idCard  = this.$store.state.selectCheckAdr.receiver_identity_card;
        }
        this.$store.commit("setCheckAdr", this.address);
      })
    },
    showPopup() {this.show = true},
    // 地址
    onEdit() {this.$router.push({name: 'MineAddress', params: {from: 'check'}})},
    onSumbit() {  //提交订单
      const {popup: attribute, radio: pay_type, expectPrice: expect_pay_amount, products, shipping_fee, value, routerEnter} = this
      const {user} = this.dataInfo
      const {email, tel} = user
      const {receiver_name: consignee, receiver_identity_card, countries_code, province_code, city_code, district_code, address, id: address_id} = this.address
      let productsPrice = 0
      products.forEach(item => {
        productsPrice += item.vip_goods_amount
        let product_num = item.number
        item.product_num = product_num
      })

      let params = {
        attribute,
        pay_type,
        goods_amount: productsPrice.toFixed(2),
        products,
        consignee,
        receiver_identity_card,
        countries_code,
        province_code,
        city_code,
        district_code,
        address,
        address_id,
        shipping_fee,
        expect_pay_amount,
        email,
        remark: value,
        mobile: tel
      }
      let arr = user.address;
      if(attribute === '1') {
        if(Object.keys(arr).length == 0) {
          return this.$toast(this.language.l_152); // 请选择地址
        } 
      }

      let orderList = this.$store.state.orderCheck.products
      let cartList = JSON.parse(localStorage.getItem('cartList'))
      let indexList = []
      let cartList_1 = []

      if(routerEnter === 'GoodsInfo') {
        cartList_1 = cartList
      } else if(routerEnter === 'Cart') {
        for(let item in cartList) {
          for(let item1 in orderList) {
            if(cartList[item].id === orderList[item1].id) {indexList.push(item)}
          }
        }
        for(let item of indexList) {delete cartList[item]}
        cartList.forEach(item => cartList_1.push(item))
      } else {
        this.$router.go(-1)
      }

      orderAdd(params).then(res => {
        const {order_id: id} = res.data
        this.$toast.success(this.language.l_199); // 支付成功
        localStorage.setItem('cartList', JSON.stringify(cartList_1))
        this.$router.replace({name: 'OrderInfo', params: {id}})
      })
    },
    sendGoods(id) {  //发货方式
      this.popup = id;
      if(this.popup == 1) {
        this.shipping_fee = this.dataInfo.shipping_fee
        this.show = false
      } else if (this.popup == 2) {
        this.shipping_fee = 0
        this.show = false
      }
    }
  },
  destroyed() {
    this.$store.commit("setCheckAdr", '');
  },
  beforeRouteEnter(to, from, next) {  //判断是哪个页面进入的
    next(vm => vm.routerEnter = from.name)
  }
};
</script>

<style lang='less' scoped>
.boxCenter {
  width: 355px;
  margin: 10px auto 0;
  border-radius: 10px;
  background-color: #fff;
}
#detail{
  // padding: 10px 10px;
  .safe_padding_bottom(50px);
  .address {
    margin: 10px 10px;
    background: #fff;
    border-radius: 10px;
    padding: 15px 10px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAE4AAAAECAYAAAAgaQkXAAAABHNCSVQICAgIfAhkiAAAAIlJREFUOI1j/P//fzEDA4MaAwGQv+S96al7vwwJqaMa+P/f8USD5AGCylITDzAwMtjTw0kMDAwMDP/+OzLOWXCAiYGBYTMx6hNteY7T2ElogLGeKGX//zfQ2CGogJGhHkIxMDAQm+riZr41uP3yjxmNnYYAgzjVMUGZRKW6Ig++MzR0DhYweFMdAAJtMXE8gh8kAAAAAElFTkSuQmCC');
    background-repeat: repeat-x;
    background-position: left bottom;
    background-size: 13%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .address_details {
      flex: 1;
      .details_layout {
        display: flex;
          span:nth-child(1) {
          padding-right: 15px;
          font-size: 16px;
          max-width: 180px;
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; /*超出部分用...代替*/
          }
          span:nth-child(2) {
            padding-right: 10px;
            font-size: 12px;
            line-height: 22px;
        }
      }
      p {
        padding-top: 8px;
        font-size: 14px;
        word-break: break-all;
      }
    }
    .right {
      text-align: right;
    }
  }
  .address-noGet {
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 14px;
    .address-noGet-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      height: 40px;
      padding: 10px 0;
      .address-noGet-left {
        display: flex;
        height: 100%;
        align-items: center;
        .icon-add {
          color: #cc3300;
          font-size: 16px;
        }
        .address-noGet-text {
          margin-left: 5px;
        }
      }
      .icon-right {
        font-size: 14px;
        margin-left: 5px;
        color: #999999;
      }
    }
  }
  .trade {
    margin: 10px 10px;
    border-radius: 10px;
    overflow: hidden;
    .order_addres {
      background: #fff;
      .order_center {
         margin: 0 10px;
         border-radius: 10px;
      }
      .quantity{
        display: block;
        margin-top: 6px;
        color: #969799;
      }
    }
    .model-header {
        width: 100%;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-size: 16px;
      }
  }
  .freight {
    margin: 10px 10px;
    border-radius: 10px;
    overflow: hidden;
  }
  .payment {
    margin: 10px 10px;
    border-radius: 10px;
    overflow: hidden;
    .pay_layout {
      /deep/ .van-cell__title {
        display: flex;
      }
      .pay_img {
        width: 20px;
        height: 20px;
        margin-right: 10px; 
      }
    }
  }
  .check {
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:nth-child(1) {
      padding-left: 15px;
      span:nth-child(1) {
        font-size: 12px;
      }
      span:nth-child(2) {
        font-size: 18px;
        color: @c_deep;
      }
      span:nth-child(3) {
        font-size: 14px;
        color: @c_deep;
      }
    }
    .fixed-right {
      width: 110px;
      height: 100%;
      line-height: 50px;
      text-align: center;
      background: @grad_right;
      color: #fff;
    }
  }
}
.icon_padding{
  padding-right: 10px;
}
</style>
